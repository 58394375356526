// import { createRouter, createWebHistory } from "vue-router";
// import Home from '../components/Home.vue';
// import WhyChooseUs from '../components/WhyChooseUs.vue';
// import Services from '../components/Services.vue';
// import ServicesDetail from '../components/ServicesDetail.vue';
// import AboutUs from '../components/AboutUs.vue';
// import Careers from '../components/Careers.vue';
// import ContactUs from '../components/ContactUs.vue';
// import PrivacyPolicy from '../components/PrivacyPolicy.vue';
// import RefundCancellation from '../components/RefundCancellation.vue';
// import CustomersFAQ from '../components/CustomersFAQ.vue';
// import WorkplacePolicy from '../components/WorkplacePolicy.vue';
// import TermsandConditions from '../components/TermsandConditions.vue';
// import Payment from '../components/Payment.vue';

// const routes = [
//     { path: '/', component: Home },
//     { path: '/why-choose-us', component: WhyChooseUs },
//     { path: '/services', component: Services },
//     { path: '/services/:serviceName', name: 'ServiceDetail', component: ServicesDetail },
//     { path: '/about-us', component: AboutUs },
//     { path: '/careers', component: Careers },
//     { path: '/contact-us', component: ContactUs },
//     { path: '/privacy-policy', component: PrivacyPolicy },
//     { path: '/refund-and-cancellation-policy', component: RefundCancellation },
//     { path: '/customers-faq', component: CustomersFAQ },
//     { path: '/workplace-policy', component: WorkplacePolicy },
//     { path: '/terms-and-conditions', component: TermsandConditions },
//     { path: '/payment', component: Payment }
// ];

// const router = createRouter({
//     history: createWebHistory(),
//     routes,
//     scrollBehavior() {
//         document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
//     }
// });

// export default router;


import { createRouter, createWebHistory } from "vue-router";

// Lazy-loaded route components
const Home = () => import('../components/Home.vue');
const WhyChooseUs = () => import('../components/WhyChooseUs.vue');
const Services = () => import('../components/Services.vue');
const ServicesDetail = () => import('../components/ServicesDetail.vue');
const AboutUs = () => import('../components/AboutUs.vue');
const Careers = () => import('../components/Careers.vue');
const ContactUs = () => import('../components/ContactUs.vue');
const PrivacyPolicy = () => import('../components/PrivacyPolicy.vue');
const RefundCancellation = () => import('../components/RefundCancellation.vue');
const CustomersFAQ = () => import('../components/CustomersFAQ.vue');
const WorkplacePolicy = () => import('../components/WorkplacePolicy.vue');
const TermsandConditions = () => import('../components/TermsandConditions.vue');
const Payment = () => import('../components/Payment.vue');

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/why-choose-us', name: 'WhyChooseUs', component: WhyChooseUs },
    { path: '/services', name: 'Services', component: Services },
    { 
        path: '/services/:serviceName', 
        name: 'ServiceDetail', 
        component: ServicesDetail,
        props: true // Allows route params to be passed as props
    },
    { path: '/about-us', name: 'AboutUs', component: AboutUs },
    { path: '/careers', name: 'Careers', component: Careers },
    { path: '/contact-us', name: 'ContactUs', component: ContactUs },
    { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
    { path: '/refund-and-cancellation-policy', name: 'RefundCancellation', component: RefundCancellation },
    { path: '/customers-faq', name: 'CustomersFAQ', component: CustomersFAQ },
    { path: '/workplace-policy', name: 'WorkplacePolicy', component: WorkplacePolicy },
    { path: '/terms-and-conditions', name: 'TermsandConditions', component: TermsandConditions },
    { path: '/payment', name: 'Payment', component: Payment },
    // Optional: Redirect unknown routes to Home or a 404 component
    { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // if (savedPosition) {
        //     return savedPosition;
        // } else {
            return { top: 0, behavior: 'smooth' };
        // }
    }
});

// Example of a global navigation guard
// router.beforeEach((to, from, next) => {
//     // Example: Protect the 'Careers' route
//     if (to.name === 'Careers') {
//         // Perform any checks here (e.g., authentication)
//         // For demonstration, we'll allow access
//         console.log(`Navigating to ${to.name} from ${from.name}`);
//     }
//     // Always call next() to proceed
//     next();
// });

// // Optional: After each navigation
// router.afterEach((to, from) => {
//     console.log(`Successfully navigated to ${to.name}`);
// });

export default router;
