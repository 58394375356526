(function ($) {
  "use strict";
  //Preloader
  window.addEventListener("load", function () {
    var preloadPage = document.getElementById("page_loader");
    if (preloadPage) {
      preloadPage.style.display = "none";
    }
  });

})(jQuery); 